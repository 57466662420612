/* Utility Classes for Speedy Container Layout  */

.horizontal {
  display: flex;
  flex-direction: row;
}

.vertical {
  display: flex;
  flex-direction: column;
}

/* .spaced */

.horizontal.spaced > * {
  margin-right: 8px;
}

.horizontal.spaced > *:last-child {
  margin-right: 0;
}

.vertical.spaced > * {
  margin-bottom: 8px;
}

.vertical.spaced > *:last-child {
  margin-bottom: 0;
}

/* Horizintal Alignment */

.left {
  justify-content: flex-start;
}

.center {
  justify-content: center;
}

.right {
  justify-content: flex-end;
}

.stretched {
  justify-content: stretch;
}

.justified {
  justify-content: space-between;
}

/* Vertical Alignment */

.top {
  align-items: flex-start;
}

.middle {
  align-items: center;
}

.bottom {
  align-items: flex-end;
}

/* Padded Container */

.horizontal.padded,
.vertical.padded {
  padding: 8px;
}

.fullWidth {
  width: 100%;
}
