/** Standard multiple for sizes and spacings */
@value unit: 8px;
/** Standard border radius */
@value borderRadius: 4px;
/** Standard border width */
@value borderWidth: 1px;

/** Breakpoints : Mobile first use `@media (min-width: smBreak)` */
@value xsBreak: 320px;
@value smBreak: 600px;
@value mdBreak: 1024px;
@value lgBreak: 1440px;
@value xlBreak: 1920px;

/** Page specifications*/
/** We do not support devices under 1024px, so we fix the minimum page and make it horizontal scrollable */
@value minPageWidth: mdBreak;

/** Column grid system specifications */

@value numCols: 12;
@value colWidth: calc(100% / numCols);
@value gutterWidth: calc(4 * unit);

/** Default speed of CSS Transitions */
@value transitionSpeed: 250ms;
