/**
* CSS util classes
*/

/** Forces the text content to fit into the container drawing the overflow with ellipsis ... */
.singleLine {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/** Avoid text highlight selection */
.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
