@value borderRadius, borderWidth from "src/ui/common/metrics.css";
@value unit from "src/ui/common/metrics.css";
@value transitionSpeed from "src/ui/common/metrics.css";

@value smallFontSize from "src/ui/common/globals.css";
@value fontWeightBold from "src/ui/common/globals.css";
@value bold from "src/ui/common/globals.css";

@value singleLine from "src/ui/common/utils.css";

@value colorBlack from "src/ui/common/colors/colors.css";
@value colorBlue from "src/ui/common/colors/colors.css";
@value colorBlue25 from "src/ui/common/colors/colors.css";
@value colorNavy from "src/ui/common/colors/colors.css";
@value colorRed from "src/ui/common/colors/colors.css";
@value colorWhite from "src/ui/common/colors/colors.css";
@value colorGray from "src/ui/common/colors/colors.css";
@value colorGrey from "src/ui/common/colors/colors.css";
@value colorGreen from "src/ui/common/colors/colors.css";
@value colorDarkGray from "src/ui/common/colors/colors.css";
@value colorLightGray from "src/ui/common/colors/colors.css";
@value colorBorder from "src/ui/common/colors/colors.css";
@value colorIconTextFont from "src/ui/common/colors/colors.css";

@value spinnerSize: calc(10 * unit);

.button {
  position: relative;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: borderRadius;
  padding: unit 0;
  border-width: borderWidth;
  border-style: solid;
  cursor: pointer;
}

.button:disabled {
  cursor: default;
}

.button.primary {
  color: colorWhite;
  font-weight: fontWeightBold;
}

button.primary.blue:not(:disabled) {
  background-color: colorBlue;
  border-color: colorBlue;
}

button.primary.red:not(:disabled) {
  background-color: colorRed;
  border-color: colorRed;
}

.button.primary.green:not(:disabled) {
  background-color: colorGreen;
  border-color: colorGreen;
}

button.primary.black:not(:disabled) {
  background-color: colorBlack;
  border-color: colorBlack;
}

.button.primary:disabled {
  background-color: colorGray;
  border-color: colorGray;
  color: colorDarkGray;
  cursor: not-allowed;
}

.button.secondary {
  color: colorNavy;
  background-color: colorWhite;
  border: 1px solid colorGrey;
  font-weight: fontWeightBold;
}

button.secondary.green:not(:disabled) {
  color: colorGreen;
  border-color: colorGreen;
}

.button.secondary:disabled {
  color: colorGray;
  cursor: not-allowed;
}

.button .content {
  display: flex;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  /**
   * IMPORTANT(jess): For unknown reasons, the height of .content
   * explodes to 8400px in Safari making the button text disappear (out of the viewport).
   * Temporary fix to set max-height to 16px to resolve this issue for now.
   */
  max-height: 16px;
}

.button .contentLabel {
  display: flex;
  align-items: center;
}

.button .leftIcon {
  margin-right: unit;
}

.button .toggleIcon {
  margin-left: unit;
  transition: transform transitionSpeed;
}

.inProgress .content {
  visibility: hidden;
}

/** Button spinner styles */
.spinner {
  position: absolute;
  left: calc(50% - 0.5 * spinnerSize);
  width: spinnerSize;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner:after {
  content: "";
  border: 8px solid colorGray;
  border-top: 8px solid colorWhite;
  border-radius: 50%;
  height: 8px;
  width: 8px;
  animation: spin 1.5s linear infinite;
  display: inline-block;
  box-sizing: unset;
}

.spinner.blue:after {
  border-top-color: colorBlue;
}

.spinner.green:after {
  border-top-color: colorGreen;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/** Group button styles */

.groupButton {
  display: flex;
  background-color: colorWhite;
}

.groupedButton {
  font-size: smallFontSize;
  border-width: borderWidth 0;
  border-radius: 0;
  border-color: colorNavy;
  composes: singleLine;
  border-style: solid;
}

.groupedButton {
  border-right-width: borderWidth;
  color: colorNavy;
}

.groupedButtonDisabled {
  border-color: colorBorder;
}

.groupedButtonDisabled + .groupedButton {
  border-left-width: borderWidth;
}

.groupedButton:last-child {
  border-right-width: borderWidth;
  border-radius: 0 borderRadius borderRadius 0;
}

.groupedButton:first-of-type {
  border-radius: borderRadius 0 0 borderRadius;
  border-left-width: borderWidth;
}

.groupedButton:hover {
  background-color: colorLightGray;
}

.groupedIcon {
  fill: colorNavy;
}

.dropdownContent {
  position: absolute;
  background-color: colorGray;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 102;
}

.subMenuContent {
  padding-top: 20px;
}

.dropdownContent a {
  color: colorNavy;
  padding: calc(1 * unit) calc(1.5 * unit);
  text-decoration: none;
  display: block;
}

.dropdownContent a:hover {
  background-color: colorBlue25;
}

.groupButtonContainer {
  padding-left: calc(1.5 * unit);
  padding-right: calc(1.5 * unit);
}

.noBorders {
  border-style: none;
}

.buttonExpanded {
  transform: rotate(180deg);
}

.subMenuTitle {
  margin-left: calc(1.5 * unit);
  padding-bottom: unit;
  color: colorBlack;
  composes: bold;
}
