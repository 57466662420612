/*
 * The definitive list of colors to be used in the app. Colors should never
 * be referenced by hex code outside this file. All colors should be represented in
 * the list below.
 *
 * Please name colors with the appropriate prefix to avoid conflicts with
 * native browser colors:
 *
 * All new colors should appear in storybook
 */

/** Specsheet colors */
@value colorBlack: #000000;
@value colorWhite: #ffffff;

@value colorNavy: #000066;
@value colorLighterNavy: #00008f;
@value colorDarkerNavy: #000033;

@value colorBlue: #0000FF;
@value colorBlue75: rgba(0, 0, 255, 0.75);
@value colorBlue50: rgba(0,0,255,0.5);
@value colorBlue25: rgba(0,0,255,0.25);

@value colorLightCharcoal: #141735;
@value colorCharcoal: #130800;
@value colorGrey: #C4C4C4;
@value colorNeutralLight: #A0AEC0;

@value colorGreen: #1cbf32;
@value colorGreen75: rgba(28,191,50,0.75);
@value colorGreen50: rgba(28,191,50,0.5);
@value colorGreen25: rgba(28,191,50,0.25);

@value colorRed: #FF4928;

@value colorOrange: #f6ab2f;

@value colorYellow: #facf55;
@value colorYellow25: rgba(250,207,85,0.25);

@value colorGray: #f4f4f4;
@value colorMediumGray: #dee1ec;
@value colorDarkGray: #3E3F42;
@value colorLightGray: #FBFBFD;
@value colorLightGreen: #E5F9EC;

/** Fonts colors */
@value colorMainTextFont: #3e3f42;
@value colorSecondaryTextFont: #6b6c6f;

@value colorTertiaryTextFont: #bcbcbc;

@value colorIconTextFont: #9ea0a5;
@value colorIconTextFont75: rgba(158, 160, 165, 0.75);
@value colorIconTextFont50: rgba(158, 160, 165, 0.5);
@value colorIconTextFont25: rgba(158, 160, 165, 0.25);

@value colorBorder: colorIconTextFont25;
@value colorPlaceholder: colorIconTextFont50;

/** Background colour of the row displayed at the end of a top-level group. */
@value endGroupRowBackgroundColour #FBFBFB;
/** Background colour of a subgroup row. */
@value subGroupRowBackgroundColour: #F3F7FC;
