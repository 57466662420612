@value baseFontSize from "src/ui/common/globals.css";
@value smallFontSize from "src/ui/common/globals.css";

@value colorBlack from "src/ui/common/colors/colors.css";
@value colorGreen from "src/ui/common/colors/colors.css";
@value colorRed from "src/ui/common/colors/colors.css";
@value colorBlue from "src/ui/common/colors/colors.css";
@value colorOrange from "src/ui/common/colors/colors.css";
@value colorWhite from "src/ui/common/colors/colors.css";
@value colorYellow from "src/ui/common/colors/colors.css";
@value colorIconTextFont from "src/ui/common/colors/colors.css";
@value colorGrey from "src/ui/common/colors/colors.css";
@value colorNavy from "src/ui/common/colors/colors.css";
@value colorLighterNavy from "src/ui/common/colors/colors.css";
@value colorSecondaryTextFont from "src/ui/common/colors/colors.css";

@value colorDarkGray: colorSecondaryTextFont;
@value colorDefault: colorGrey;

.icon {
  fill: colorDefault;
  width: baseFontSize;
  height: baseFontSize;
  display: inline-block;
}

/** Sizes */
.icon-xs {
  width: smallFontSize;
  min-width: smallFontSize;
  height: smallFontSize;
}

.icon-1x {
  width: baseFontSize;
  min-width: baseFontSize;
  height: baseFontSize;
}

.icon-sm {
  width: calc(1.5 * baseFontSize);
  height: calc(1.5 * baseFontSize);
}

.icon-16px {
  /* Fixed size 16px icon for new menu */
  width: 16px;
  height: 16px;
}

.icon-24px {
  /* Fixed size 24px icon for new menu */
  width: 24px;
  height: 24px;
}

.icon-2x {
  width: calc(2 * baseFontSize);
  height: calc(2 * baseFontSize);
}

.icon-3x {
  width: calc(3 * baseFontSize);
  height: calc(3 * baseFontSize);
}

.icon-4x {
  width: calc(4 * baseFontSize);
  height: calc(4 * baseFontSize);
}

.icon-5x {
  width: calc(5 * baseFontSize);
  height: calc(5 * baseFontSize);
}

.icon-6x {
  width: calc(6 * baseFontSize);
  height: calc(6 * baseFontSize);
}

/** Colors */
.gray {
  /* TODO(jess): Rename this to grey. */
  fill: colorDefault;
}

.darkGray {
  fill: colorDarkGray;
}

.black {
  fill: colorBlack;
}

.white {
  fill: colorWhite;
}

.red {
  fill: colorRed;
}

.green {
  fill: colorGreen;
}

.blue {
  fill: colorBlue;
}

.orange {
  fill: colorOrange;
}

.yellow {
  fill: colorYellow;
}

.navy {
  fill: colorLighterNavy;
}

.clickable {
  cursor: pointer;
}
