@value unit from "src/ui/common/metrics.css";
@value borderWidth from "src/ui/common/metrics.css";
@value bold from "src/ui/common/globals.css";
@value colorIconTextFont75 from "src/ui/common/colors/colors.css";
@value colorBorder from "src/ui/common/colors/colors.css";
@value colorGrey from "src/ui/common/colors/colors.css";
@value colorGray from "src/ui/common/colors/colors.css";
@value colorLightGray from "src/ui/common/colors/colors.css";
@value colorNavy from "src/ui/common/colors/colors.css";
@value colorCharcoal from "src/ui/common/colors/colors.css";
@value colorWhite from "src/ui/common/colors/colors.css";
@value colorBlue from "src/ui/common/colors/colors.css";

@value modalSideContentPadding: calc(3 * unit);
@value modalTopBottomContentPadding: calc(2 * unit);
@value modalContentPadding: modalTopBottomContentPadding modalSideContentPadding;

@value modalXsSize: 320px;
@value modalSmSize: 480px;
@value modalMdSize: 640px;
@value modalLgSize: 1048px;

/** General css styles */

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: colorIconTextFont75;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc(3 * unit);
}

.modal {
  width: modalLgSize;
  border: borderWidth solid colorBorder;
  background: colorWhite;
  outline: none;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

.body {
  background-color: colorWhite;
}

.closeIcon {
  position: absolute;
  right: calc(2 * unit);
  top: calc(2 * unit);
}

.content {
  padding: modalContentPadding;
}

.buttons {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: modalContentPadding;
  border-top: borderWidth solid colorBorder;
  background: colorLightGray;
}

.buttons > * {
  width: auto;
  min-width: 25%;
}

/** Base css styles for a modal with custom width */
.sizesModal {
}

/** XS modal styles */
.xsModal {
  composes: sizesModal;
  width: modalXsSize;
}

.xsModal .buttons {
  padding: unit modalSideContentPadding;
}

/** Reduced small size modal */
.smModal {
  composes: sizesModal;
  width: modalSmSize;
}

/** Reduced medium size modal */
.mdModal {
  composes: sizesModal;
  width: modalMdSize;
}

.lgModal {
  composes: sizesModal;
  width: modalLgSize;
}

.customPlacementModal {
  height: 650px;
  composes: mdModal;
}

.modalHeading {
  color: colorNavy;
  padding: 30px;
  margin-bottom: 0;
  padding-bottom: 5px;
}

.greyInfoBox {
  /* TODO(jess): Add this grey colour to colors.css. */
  background-color: #f4f4f4;
  color: colorCharcoal;
  font-size: 12px;
  line-height: 16px;
  padding: 10px;
  margin-bottom: 30px;
}

.list {
  list-style: disc;
  padding-left: 30px;
  padding-top: 10px;
}

.list li {
  margin-bottom: 5px;
}

.paragraph {
  padding: 20px;
}

.campaignVersionChangesModalContent {
  overflow-y: auto;
  max-height: 250px;
}

.campaignVersionInfo {
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-bottom: 10px;
}

.campaignVersionInfo > *:not(:last-child) {
  margin-bottom: 10px;
}

.scrollableContentBox {
  overflow-y: auto;
  padding: 10px;
  border: 1px solid colorGrey;
  margin-top: 10px;
  max-height: 250px;
}

.publishModalContent b {
  color: colorCharcoal;
}

.messageInput {
  height: 160px;
}

.campaignSubscriberBox {
  line-height: 20px;
  background-color: colorLightGray;
  color: colorCharcoal;
}

.section {
  margin-top: 30px;
}

.placementModal {
}

.placementModalContainer {
  background: colorGray;
}

.placementModalOverlay {
  z-index: 1400;
}

.placementModalBody {
  background: colorGray;
}

.placementModalButtons {
  background: colorGray;
  border-top: none;
}

.manageUsersContent b {
  color: colorCharcoal;
}

.usersEditSection {
  overflow-y: auto;
  max-height: 500px;
  padding-right: calc(2 * unit);
}

.emailSpan {
  margin-top: unit;
}

.addEmailButtonLink {
  color: colorBlue;
  composes: bold;
  line-height: 16px;
  height: fit-content;
  padding: calc(2 * unit);
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.manageUsersLink {
  composes: addEmailButtonLink;
  padding-left: 0;
  padding-bottom: 0;
}

.emailInput {
  flex-grow: 3;
}

.disabledAddButtonLink {
  color: colorGrey;
}

.addEmailContainer {
  margin-bottom: calc(0.5 * unit);
}

.deleteEmailIcon {
  margin-top: unit;
}

.noUsers {
  margin-top: unit;
  color: colorGrey;
}

.saveNotesButton {
  padding: unit;
}

.addPlacementsButton {
  padding-left: unit;
  padding-right: unit;
}

.modifyCampaignButton {
  padding-left: calc(2 * unit);
  padding-right: calc(2 * unit);
}
